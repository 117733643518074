.announcement-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

#desktop-announcement-box {
    height: 30em;
    width: 30em;
    padding: 5em;
}

/* announcement-box anims */

.session-state-appear .announcement-box,
.session-state-enter .announcement-box {
    animation-name: slide-from-left;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.session-state-exit .announcement-box {
    animation-name: slide-from-right;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    animation-direction: reverse;
}