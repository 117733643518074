.board-panel-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.board-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 80%;
    padding: 1em;
}

/* even board panel anims */

.session-state-appear .board-panel-wrapper.even,
.session-state-appear-done .board-panel-wrapper.even,
.session-state-enter .board-panel-wrapper.even,
.session-state-enter-done .board-panel-wrapper.even {
    animation-name: slide-from-top;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.session-state-exit-active .board-panel-wrapper.even {
    animation-name: slide-from-top;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
    animation-fill-mode: both;
}

/* odd board panel anims */

.session-state-appear .board-panel-wrapper.odd,
.session-state-appear-done .board-panel-wrapper.odd, 
.session-state-enter .board-panel-wrapper.odd,
.session-state-enter-done .board-panel-wrapper.odd {
    animation-name: slide-from-bottom;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.session-state-exit-active .board-panel-wrapper.odd {
    animation-name: slide-from-bottom;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
    animation-fill-mode: both;
}