@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes slide-from-left {
    0% {
        transform: translateX(-100vw);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slide-from-right {
    0% {
        transform: translateX(100vw);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slide-from-top {
    0% {
        transform: translateY(-100vh);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes slide-from-bottom {
    0% {
        transform: translateY(100vh);
    }

    100% {
        transform: translateY(0);
    }
}