body {
    margin: 0;
    text-align: center;
    user-select: none;
}

.box {
    background-color: white;
    box-shadow: 7px 7px black;
}

.mobile-box {
    background-color: white;
    box-shadow: 5px 5px black;
}

.child-box {
    background-color: white;
    box-shadow: 4px 4px black;
    outline: black solid 2px;
}