/* game over box anims */

.session-state-appear #game-over-box, 
.session-state-enter #game-over-box {
    animation-name: slide-from-top;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.session-state-exit #game-over-box {
    animation-name: slide-from-top;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
    animation-fill-mode: both;
}

/* even scoreboard player box anims */

.session-state-appear .scoreboard-player-box.even, 
.session-state-enter .scoreboard-player-box.even {
    animation-name: slide-from-left;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.session-state-exit .scoreboard-player-box.even {
    animation-name: slide-from-left;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
    animation-fill-mode: both;
}

/* odd scoreboard player box anims */

.session-state-appear .scoreboard-player-box.odd, 
.session-state-enter .scoreboard-player-box.odd {
    animation-name: slide-from-right;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.session-state-exit 
.scoreboard-player-box.odd {
    animation-name: slide-from-right;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
    animation-fill-mode: both;
}