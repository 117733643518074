#signature-box {
    margin-right: 0.5em;
    height: 7em;
    width: 7em;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.submitted-responders-box {
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
    height: 5em;
    min-width: 25vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* clue decision anims */

.clue-decision-enter {
    animation-name: fade-in;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.clue-decision-exit {
    animation-name: fade-in;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
    animation-fill-mode: both;
}

/* responder info anims */

.responder-info-appear {
    animation-name: slide-from-bottom;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.responder-info-enter {
    animation-name: slide-from-bottom;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.responder-info-exit {
    animation-name: slide-from-bottom;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
    animation-fill-mode: both;
}

.session-state-enter #responder-info-box {
    animation-name: slide-from-bottom;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.session-state-exit #responder-info-box {
    animation-name: slide-from-bottom;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
    animation-fill-mode: both;
}