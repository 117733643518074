#question-box {
    height: 50vh;
    padding: 2em;
    justify-content: center;
    align-content: center;
    overflow: auto;
}

#correct-answer {
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 1em;
}

/* category box anims */

.session-state-appear #category-box,
.session-state-enter #category-box {
    animation-name: slide-from-left;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.session-state-exit #category-box {
    animation-name: slide-from-left;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
    animation-fill-mode: both;
}

/* question box anims */

.question-box-appear,
.question-box-enter {
    animation-name: slide-from-right;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.question-box-exit,
.session-state-exit #question-box {
    animation-name: slide-from-right;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
    animation-fill-mode: both;
}

.correct-answer-appear,
.correct-answer-enter {
    animation-name: fade-in;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}