.timer-wrapper {
    position: fixed;
    top: 1em;
    left: 1em;
}

#desktop-timer-wrapper {
    height: 6em;
    width: 6em;
}

#mobile-timer-wrapper {
    height: 3em;
    width: 3em;
}

#timer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

/* timer anims */

.timer-appear .timer-wrapper,
.timer-enter .timer-wrapper {
    animation-name: slide-from-left;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
}

.timer-exit .timer-wrapper {
    animation-name: slide-from-left;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    animation-direction: reverse;
}