/* category box anims */

.session-state-appear #category-box, 
.session-state-enter #category-box {
    animation-name: slide-from-top;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.session-state-exit #category-box {
    animation-name: slide-from-top;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
    animation-fill-mode: both;
}