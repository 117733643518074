.side-box {
    min-height: 10em;
    max-height: 40em;
    width: 20em;
    padding: 2em;
    margin-top: auto;
    margin-bottom: auto;
    overflow: auto;
}

#joined-players-box {
    margin-right: 2em;
}

#leaderboard-box {
    margin-left: 2em;
}

/* logo box anims */

.session-state-appear #logo-box,
.session-state-enter #logo-box {
    animation-name: slide-from-top;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.session-state-exit #logo-box {
    animation-name: slide-from-top;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
    animation-fill-mode: both;
}

/* game settings preset box anims */

.session-state-appear #game-settings-preset-box,
.session-state-enter #game-settings-preset-box {
    animation-name: slide-from-bottom;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.session-state-exit #game-settings-preset-box {
    animation-name: slide-from-bottom;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
    animation-fill-mode: both;
}

/* leaderboard box anims */

.session-state-appear #leaderboard-box,
.session-state-enter #leaderboard-box {
    animation-name: slide-from-right;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.session-state-exit #leaderboard-box {
    animation-name: slide-from-right;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
    animation-fill-mode: both;
}

/* joined players box anims */

.session-state-appear #joined-players-box,
.session-state-enter #joined-players-box {
    animation-name: slide-from-left;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.session-state-exit #joined-players-box {
    animation-name: slide-from-left;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
    animation-fill-mode: both;
}