@font-face {
    font-family: "logo";
    src: url("../fonts/square.ttf");
}

@font-face {
    font-family: "board";
    src: url("../fonts/rheiborn-sans-clean.ttf");
}

@font-face {
    font-family: "clue";
    src: url("../fonts/optikorinna-agency.otf");
}